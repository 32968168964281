<template>
  <div :style="getElementStyle">
    <el-row v-if="isActive || isDataTableField ">
      <el-col :span="3">
          <span
            class="setting-icon"
            @click="openSettings"
          >
            <i class="el-icon-s-tools" /> </span>
        </el-col>
      </el-row>
  <div  class="before-field" v-if="!isDefalutPos">
      {{ data.label }}
      <span v-if="data.validations.required" style="color: red; font-weight: bold;">
        *
      </span>
      <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
      <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
    <div class="icon-block" :style="getStyle"  >
      <!-- <div  v-if="data.input_type = 'ENTITY_VARIABLE'">
        <el-link class="hyperlnk">Download Attachments</el-link>
      </div> -->
      <div class="icon avatar-uploader">
        <img
          :height="this.data.height - 50"
          src="@/assets/img/icons/upload-icon.svg"
          alt="icon"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="!isInDataTable" >
      {{ data.label }}
      <span v-if="data.validations.required" style="color: red; font-weight: bold;">
        *
      </span>
      <i
        class="el-icon-copy-document " @click="copyDocument" ></i>
    </div>
    <div class="icon-block" :style="getStyle"  >
      <!-- <div  v-if="data.input_type = 'ENTITY_VARIABLE'">
        <el-link class="hyperlnk">Download Attachments</el-link>
      </div> -->
      <div class="icon avatar-uploader">
        <img
          :height="this.data.height - 50"
          src="@/assets/img/icons/upload-icon.svg"
          alt="icon"
        />
      </div>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
        style="padding-left: 10px"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["data", "isActive", "isDataTableField","isInDataTable"],
  computed: {
    getStyle() {
      return (
        `height:${this.data.height - 30}px` +
        (this.data.styles && this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "")
      );
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }

      // return isDefalutPosq;
    },
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    getElementStyle() {
      let borderStyle = "";

      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }

      return borderStyle;
    }
  
  },
 
  data() {
    return {
      validations: [],
      showLabel: true,
      inputTextAlignmentValue: 'left',
    };
  },
  mounted() {
    if (this.data.styles) {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        // if (this.data.styles.labelStyle == "right") {
        //   this.isDefalutPos = false;
        // }
      }
    }},
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  height: inherit !important;
  justify-content: center !important;
}
.before-field{
 display:flex;
 align-items:center;
}

.attachment_img {
  height: inherit !important;
}
.info-right {
  margin-left: 90px;
}

.hyperlnk {
  color: blue;
}

.setting-icon {
  padding-left: 10px;
}
</style>
